import { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import axios from 'axios';

import { useClient } from 'src/contexts/ClientsContext';

interface State {
  id: string;
  sigla: string;
  nome: string;
}

interface City {
  id: string;
  nome: string;
}

export function FilterModal() {
  const { openModal, handleCloseModal, handleFilters } = useClient();

  const [state, setState] = useState('');
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  const [city, setCity] = useState('');
  const [initialRange, setInitialRange] = useState('');
  const [finalRange, setFinalRange] = useState('');

  const theme = useTheme();

  function formatDate(date: string) {
    return date.split('T')[0];
  }

  async function getStates() {
    const { data } = await axios.get<State[]>(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
    );

    setStates(data);
  }

  async function getCities() {
    const { data } = await axios.get<State[]>(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
    );

    setCities(data);
  }

  function handleConfirm() {
    handleFilters({
      city,
      state,
      initialRange: formatDate(initialRange),
      finalRange: formatDate(finalRange),
    });
    handleCloseModal();
  }

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Dialog onClose={handleCloseModal} open={openModal}>
      <DialogTitle>Consulta de clientes</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Box>
            <Box sx={{ mt: '1.25rem' }}>
              <Typography variant="subtitle2" color={theme.palette.primary.main}>
                Data de adesão
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem' }}>
                <TextField
                  size="small"
                  type="date"
                  value={initialRange}
                  onChange={(e) => setInitialRange(e.target.value)}
                />
                <Typography
                  variant="subtitle2"
                  color={theme.palette.primary.main}
                  sx={{ mx: '0.5rem' }}
                >
                  Até
                </Typography>
                <TextField
                  size="small"
                  type="date"
                  value={finalRange}
                  onChange={(e) => setFinalRange(e.target.value)}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: '4rem' }}>
              <Box sx={{ marginTop: '1.25rem' }}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Base Do Cliente
                </Typography>
                <Select
                  sx={{ width: '220px' }}
                  displayEmpty
                  size="small"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <em>Base Do Cliente</em>
                  </MenuItem>
                  {states.map((state) => (
                    <MenuItem value={state.sigla} key={state.id}>
                      {state.sigla}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ marginTop: '1.25rem' }}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Cidade
                </Typography>
                <Select
                  sx={{ width: '220px' }}
                  displayEmpty
                  size="small"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <em>Cidade</em>
                  </MenuItem>
                  {cities.map((city) => (
                    <MenuItem value={city.nome} key={city.id}>
                      {city.nome}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              marginTop: '1rem',
            }}
          >
            <Button variant="outlined" onClick={handleCloseModal}>
              cancelar
            </Button>
            <Button variant="contained" sx={{ marginLeft: '20px' }} onClick={handleConfirm}>
              confirmar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
