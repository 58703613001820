import { styled, useTheme } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import Logo from 'src/components/Logo';
import SocialsButton from 'src/components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Legal',
    children: [
      {
        name: 'Política de Privacidade',
        href: 'https://planosempre.com.br/politica-de-privacidade/',
        targetBlank: true,
        lead: true,
        dependent: true,
      },
      {
        name: 'Termo de Uso',
        href: '/dashboard/term-of-use',
        targetBlank: false,
        lead: true,
        dependent: true,
      },
    ],
  },
  {
    headline: 'Contato',
    children: [
      {
        name: 'contato@empresavila.com.br',
        href: 'mailto:contato@irmaosvila.com.br?Subject=SOBRE%20O%20PLANO%20SEMPRE',
        lead: true,
        dependent: true,
      },

      {
        name: '4000-2002 (capitais e regiões metropolitanas)',
        href: 'tel:40002002',
        targetBlank: false,
        lead: true,
        dependent: true,
      },
      {
        name: '0800 402 2002 (demais localidades)',
        href: 'tel:08004022002',
        targetBlank: false,
        lead: true,
        dependent: true,
      },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: '25px 0 0',
  bottom: 0,
  backgroundColor: theme.palette.grey[100],
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  return (
    <RootStyle>
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'left', md: 'space-between' }}
          sx={{ textAlign: { xs: 'left', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { md: 'inherit' } }} />
          </Grid>

          <Grid item md={3}>
            <Typography variant="body2">
              Amparo e cuidado são sinônimos dessa marca que está há mais de 18 anos no mercado,
              protegendo mais de 700 mil vidas, em 200 cidades entre o Rio Grande do Norte e
              Paraíba. O sempre é mais que assistência funeral, é assistência familiar + benefícios
              que valem a pena!
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'left', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" color={theme.palette.grey[600]}>
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      href={link.href}
                      target={link.targetBlank ? '_blank' : '_self'}
                      key={link.name}
                      color={theme.palette.primary.main}
                      variant="body2"
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'left', md: 'left' },
          }}
        >
          SEMPRE {process.env.REACT_APP_NAME} © 2023 | Todos os direitos reservados.
        </Typography>
      </Container>
    </RootStyle>
  );
}
