import { Container, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Terms } from 'src/components/Terms';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentInfo = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'wrap',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
  borderRadius: '16px',
  padding: '24px 24px',
  mt: '1rem',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));


// ----------------------------------------------------------------------

export function TermOfUse() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

  return (
    <RootStyle>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div style={{ display: 'flex' }}>
          <HeaderBreadcrumbs
            heading="Termo de Uso"
            links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Termo de Uso' }]}
          />
        </div>
        <Divider sx={{ marginBottom: '20px' }} />
        <ContentStyle>
          <ContentInfo>
            <Terms />
          </ContentInfo>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
