import { useEffect, useState } from 'react';
import { Accordion, Button, Card, useTheme, Menu, MenuItem, Box } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { EmployeeDetail } from '../employee/EmployeeDetail';
import { useLogs } from 'src/hooks/useLogs';
import { EmployeeSummary } from './EmployeeSummary';
import { SearchBarEmployee } from './SearchBarEmployee';
import EmptyContent from 'src/components/EmptyContent';
import { useManagementEmployee } from 'src/hooks/useManagementEmployee';
import { IPartnes } from '../employee/RegisterOrEditEmployee';
import { filters } from '../employee/ListEmployeeInactive';

export interface ListClientsProps {
  list: {
    id: string;
    cpf: string;
    name: string;
    photoURL: string;
    contract: string;
    active: boolean;
  }[];
}

export function ListEmployeeActive() {
  const theme = useTheme();
  const { employees, getEmployees, handleFilter } = useLogs();
  const { handleGetPartnes } = useManagementEmployee();
  const [partnes, setPartnes] = useState<IPartnes[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseFilter(filter: string) {
    if (typeof filter === 'string') {
      handleFilter(filter);
    }
    setAnchorEl(null);
  }

  const getPartnes = async () => {
    const data = await handleGetPartnes();
    setPartnes(data);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPartnes();
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card style={{ padding: '10px' }}>
      <div
        style={{
          paddingBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBarEmployee />
          <Button onClick={handleClickFilter}>
            <Iconify icon="codicon:list-filter" color={theme.palette.primary.main} />
            Filtrar
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleCloseFilter}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {filters.map((filter) => (
              <MenuItem key={filter.name} onClick={() => handleCloseFilter(filter.type)}>
                {filter.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {employees.length !== 0 ? (
        employees
          .filter((employee) => employee.active)
          .map((employee, index) => (
            <Box key={employee.id} borderBottom={1} borderColor={theme.palette.grey[300]}>
              <Accordion disableGutters elevation={0} square>
                <EmployeeSummary index={index} employee={employee} />
                <EmployeeDetail employee={employee} partnes={partnes} />
              </Accordion>
            </Box>
          ))
      ) : (
        <EmptyContent title="Não há usuários!" />
      )}
    </Card>
  );
}
