import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, DialogContent, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import axiosInstance from 'src/utils/axios';
import * as Yup from 'yup';

import { useTheme } from '@mui/material/styles';
import { TiWarning } from 'react-icons/ti';
import { toast } from 'react-toastify';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type FormValuesProps = {
  password: string;
  confirmPassword: string;
};
const BoxButtonUpdate = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: '100%',
  marginTop: '35px',
}));

const regexPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm || '';

export default function ChangePasswordFirstAccess() {
  const { user } = useAuth();
  const [open, setOpen] = useState<boolean>(user?.change_password);
  const [showPasswordText, setShowPasswordText] = useState(false);

  const theme = useTheme();

  const newPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Digite uma senha')
      .matches(regexPassword, 'A senha não atende os requisitos de segurança!'),

    confirmPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(newPasswordSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.patch(`admin/employees/new-password/${user?.id}`, {
        password: data.password,
      });

      setOpen(false);
      toast.success('Senha alterada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error('Falha na alteração de Senha!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };
  return (
    <Dialog open={open}>
      <DialogContent sx={{ maxWidth: '700px', maxHeight: '674px', padding: '0px' }}>
        <div
          style={{
            padding: '20px 20px 10px 20px',
            borderBottom: '1px solid  rgba(145, 158, 171, 0.24)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" fontWeight={600} fontSize="18px">
            Você deve realizar a troca de senha!
          </Typography>
        </div>

        <Box sx={{ width: '100%', marginTop: '8px', padding: '20px' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Box sx={{ marginTop: '1.25rem' }}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Nova Senha
                </Typography>
                <RHFTextField
                  size="small"
                  name="password"
                  type={showPasswordText ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPasswordText(!showPasswordText)}
                          edge="end"
                        >
                          <Iconify icon={showPasswordText ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ marginTop: '1.25rem' }}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Confirmar Senha
                </Typography>
                <RHFTextField
                  size="small"
                  name="confirmPassword"
                  type={showPasswordText ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPasswordText(!showPasswordText)}
                          edge="end"
                        >
                          <Iconify icon={showPasswordText ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  backgroundColor: theme.palette.warning.lighter,
                  color: theme.palette.warning.darker,
                  borderRadius: '10px',
                  padding: '15px 0',
                  margin: '10px 0',
                  fontSize: '12px',
                  fontWeight: '500',
                  textAlign: 'left',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', padding: '0 5px' }}>
                  <TiWarning size={'25px'} color={theme.palette.warning.main} />
                </div>
                <div>
                  A senha deve ter no mínimo 8 caracteres,e conter pelo menos:
                  <ul style={{ listStyle: 'none' }}>
                    <li>1 letra maiúscula;</li>
                    <li>1 letra minúscula;</li>
                    <li>1 número;</li>
                    <li>1 caractere especial (Exemplo: @#$%^&+!=?*-/).</li>
                  </ul>
                </div>
              </div>
            </Stack>
            <BoxButtonUpdate>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Confirmar
                <MdOutlineKeyboardArrowRight />
              </LoadingButton>
            </BoxButtonUpdate>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
