import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import { useManagementEmployee } from 'src/hooks/useManagementEmployee';
import useSettings from 'src/hooks/useSettings';
import useTabs from 'src/hooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ListEmployeeActives } from 'src/sections/@dashboard/employee/ListEmployeeActives';
import { ListEmployeeInactive } from 'src/sections/@dashboard/employee/ListEmployeeInactive';

export function ManagementAccessEmployee() {
  const { themeStretch } = useSettings();
  const { currentTab, onChangeTab } = useTabs('Usuários Ativos');

  const { getEmployees } = useManagementEmployee();

  const ACCOUNT_TABS = [
    {
      value: 'Usuários Ativos',
      icon: <Iconify icon={'akar-icons:sun'} width={20} height={20} />,
      component: <ListEmployeeActives />,
    },

    {
      value: 'Usuários Inativos',
      icon: <Iconify icon={'akar-icons:sun'} width={20} height={20} />,
      component: <ListEmployeeInactive />,
    },
  ];

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Gerenciar Acessos">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Gerenciar Acessos"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Gerenciar Acessos' }]}
        />
        <Divider />
        <Tabs value={currentTab} onChange={onChangeTab}>
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
