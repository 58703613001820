import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  useTheme,
  Stack,
  Box,
  InputAdornment,
  IconButton,
  Alert,
  TextField,
  Divider,
  Switch,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { cpf } from 'cpf-cnpj-validator';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import InputMask from 'react-input-mask';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { toast } from 'react-toastify';
import axiosInstance from 'src/utils/axios';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from 'src/components/Iconify';
import { TiWarning } from 'react-icons/ti';
import { Employee } from 'src/contexts/ManagementEmployeeContext';

export const regexPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm || '';

export const ROLES = [
  {
    title: 'ADMINISTRADOR',
    value: 'ADMIN',
  },
  {
    title: 'PARCEIRO',
    value: 'PARTNER',
  },
];

type FormValuesProps = {
  role: string;
  company_id?: string | null;
  name: string;
  email: string;
  cpf: string;
  contact: string;
  password: string;
  confirmPassword: string;
  afterSubmit?: string;
};

export interface IPartnes {
  id: string;
  name: string;
  cnpj: string;
  active: boolean;
}

export interface RegisterOrEditEmployeeProps {
  open: boolean;
  onClose: () => void;
  edit?: boolean;
  colaborator?: Employee;
  partnes?: IPartnes[];
}

export function RegisterOrEditEmployee(props: RegisterOrEditEmployeeProps) {
  const { onClose, open, edit = false, colaborator } = props;

  const theme = useTheme();

  const setValues = () => {
    setValue('role', colaborator?.role || 'ADMIN');
    setValue('name', colaborator?.name || '');
    setValue('contact', colaborator?.phone_number || '');
    setValue('email', colaborator?.email || '');
    setValue('company_id', colaborator?.company_id || undefined);
  };

  const handleClose = () => {
    onClose();
    // setValue('role', 'Selecione');
    // setValue('name', '');
    // setValue('contact', '');
    // setValue('email', '');
    // setValue('password', '');
    // setValue('confirmPassword', '');
    setEditPassword(false);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  function handleChange() {
    setEditPassword(!editPassword);
  }

  const isMountedRef = useIsMountedRef();

  //const theme = useTheme();

  const newEmployeeSchema = Yup.object().shape({
    cpf: Yup.string()
      .required('Digite um CPF')
      .test('CPF inválido!', 'CPF inválido', (value: any) => cpf.isValid(value)),
    role: Yup.string().required('Escolha um tipo de permissão!').nullable(),
    company_id: Yup.string().when('role', {
      is: 'PARTNER',
      then: Yup.string().typeError('Selecione um PARCEIRO').required('Selecione um PARCEIRO'),
      otherwise: Yup.string().nullable(), // O company_id não é obrigatório se o role não for PARTNER
    }),
    name: Yup.string().required('Digite o nome completo').nullable(),
    email: Yup.string().email('digite um E-mail valido').required('Digite o e-mail').nullable(),
    contact: Yup.string().required('Digite o número de contato').nullable(),
    password:
      !edit || editPassword
        ? Yup.string()
            .required('Digite uma senha')
            .matches(regexPassword, 'A senha não atende os requisitos de segurança!')
        : Yup.string(),
    confirmPassword:
      !edit || editPassword
        ? Yup.string()
            .required('')
            .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
        : Yup.string(),
  });

  const defaultValues = {
    role: colaborator?.role,
    name: colaborator?.name,
    email: colaborator?.email,
    cpf: colaborator?.cpf,
    contact: colaborator?.phone_number,
    company_id: colaborator?.company_id,
    password: '',
    confirmPassword: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(newEmployeeSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const roleSelected = watch('role', '');

  const onSubmit = edit
    ? async (data: FormValuesProps) => {
        try {
          editPassword
            ? await axiosInstance.patch(`/admin/employees/${colaborator?.id}`, {
                password: data.password,
                email: data.email,
                role: data.role,
                name: data.name,
                company_id: data.company_id || null,
                cpf: data.cpf.replace(/[^0-9]/g, ''),
                phone_number: data.contact.replace(/[^0-9]/g, ''),
              })
            : await axiosInstance.patch(`/admin/employees/${colaborator?.id}`, {
                email: data.email,
                role: data.role,
                name: data.name,
                company_id: data.company_id || null,
                cpf: data.cpf.replace(/[^0-9]/g, ''),
                phone_number: data.contact.replace(/[^0-9]/g, ''),
              });
          handleClose();
          toast.success('Usuário alterado com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } catch (error) {
          toast.error('Falha ao alterar o usuário!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          reset();

          if (isMountedRef.current) {
            setError('afterSubmit', { ...error, message: error.error });
          }
        }
      }
    : async (data: FormValuesProps) => {
        try {
          await axiosInstance.post('/admin/employees', {
            email: data.email,
            password: data.password,
            role: data.role,
            name: data.name,
            cpf: data.cpf.replace(/[^0-9]/g, ''),
            company_id: data.company_id || null,
            phone_number: data.contact.replace(/[^0-9]/g, ''),
          });
          handleClose();

          toast.success('usuário adicionado com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } catch (error) {
          toast.error('Falha ao adicionar usuário!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          reset();

          if (isMountedRef.current) {
            setError('afterSubmit', { ...error, message: error.error });
          }
        }
      };

  useEffect(() => {
    setValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colaborator]);

  // JSX
  return (
    <Dialog onClose={handleClose} open={open}>
      {edit ? (
        <DialogTitle>Alterar usuário</DialogTitle>
      ) : (
        <DialogTitle>Cadastrar usuário</DialogTitle>
      )}

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            {edit && (
              <>
                <Typography variant="subtitle2" color={theme.palette.info.dark}>
                  {colaborator?.name.toUpperCase()}
                </Typography>

                <Divider />
              </>
            )}

            <Box sx={{ margin: '1.25rem 0' }}>
              <Typography variant="overline" color={theme.palette.grey[600]}>
                informações gerais
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Nome
                  </Typography>
                  <RHFTextField
                    sx={{ width: '270px' }}
                    size="small"
                    name="name"
                    autoComplete="off"
                  />
                </Box>

                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    CPF
                  </Typography>
                  <Controller
                    name="cpf"
                    control={methods.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <InputMask mask="999.999.999-99" value={value} onChange={onChange}>
                        <TextField
                          size="small"
                          name="contact"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          error={Boolean(errors.cpf)}
                          helperText={error?.message}
                          sx={{ width: '270px' }}
                        />
                      </InputMask>
                    )}
                  />
                </Box>

                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Permissão
                  </Typography>

                  <RHFSelect sx={{ width: '270px' }} size="small" name="role" fullWidth>
                    <option value="0" disabled selected hidden>
                      Selecione
                    </option>
                    {ROLES.map((i) => (
                      <option key={i.title} value={i.value}>
                        {i.title}
                      </option>
                    ))}
                  </RHFSelect>
                </Box>

                {roleSelected === 'PARTNER' && (
                  <Box sx={{ marginTop: '1.25rem' }}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Parceiro
                    </Typography>

                    <RHFSelect sx={{ width: '270px' }} size="small" name="company_id" fullWidth>
                      <option value="" selected>
                        Selecione
                      </option>
                      {props.partnes &&
                        props.partnes.map((i, index) => (
                          <option key={index} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </RHFSelect>
                  </Box>
                )}
                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Email
                  </Typography>
                  <RHFTextField
                    sx={{ width: '270px' }}
                    size="small"
                    autoComplete="off"
                    type="email"
                    name="email"
                  />
                </Box>

                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography
                    sx={{ width: '270px' }}
                    variant="subtitle2"
                    color={theme.palette.primary.main}
                  >
                    Contato
                  </Typography>
                  <Controller
                    name="contact"
                    control={methods.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <InputMask mask="(99) 9 9999-9999" value={value} onChange={onChange}>
                        <TextField
                          size="small"
                          name="contact"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          error={Boolean(errors.contact)}
                          helperText={error?.message}
                        />
                      </InputMask>
                    )}
                  />
                </Box>
              </Box>
            </Box>
            {edit && !editPassword && (
              <Box sx={{ marginTop: '1.25rem' }}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Deseja alterar a senha?
                </Typography>
                <Switch
                  checked={editPassword}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            )}

            {!edit || editPassword ? (
              <>
                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Senha
                  </Typography>
                  <RHFTextField
                    autoComplete="off"
                    sx={{ width: '270px' }}
                    size="small"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Confimar Senha
                  </Typography>
                  <RHFTextField
                    autoComplete="off"
                    sx={{ width: '270px' }}
                    size="small"
                    name="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    backgroundColor: theme.palette.warning.lighter,
                    color: theme.palette.warning.darker,
                    borderRadius: '10px',
                    padding: '15px 0',
                    margin: '10px 0',
                    marginTop: '30px',
                    fontSize: '12px',
                    fontWeight: '500',
                    textAlign: 'left',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '0 5px' }}>
                    <TiWarning size={'25px'} color={theme.palette.warning.main} />
                  </div>
                  <div>
                    A senha deve ter no mínimo 8 caracteres,e conter pelo menos:
                    <ul style={{ listStyle: 'none' }}>
                      <li>1 letra maiúscula;</li>
                      <li>1 letra minúscula;</li>
                      <li>1 número;</li>
                      <li>1 caractere especial (Exemplo: @#$%^&+!=?*-/).</li>
                    </ul>
                  </div>
                </div>
              </>
            ) : null}
          </Stack>

          <LoadingButton
            style={{ marginTop: '1px' }}
            fullWidth
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Salvar usuário
          </LoadingButton>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
