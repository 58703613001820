import { Accordion, Card, useTheme, Box, Button, Menu, MenuItem } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { EmployeeDetail } from './EmployeeDetail';
import { EmployeeSummary } from './EmployeeSummary';
import { SearchBar } from './SearchBar';
import React, { useEffect, useState } from 'react';
import { useManagementEmployee } from 'src/hooks/useManagementEmployee';
import EmptyContent from 'src/components/EmptyContent';
import { IPartnes, RegisterOrEditEmployee } from './RegisterOrEditEmployee';
import { filters } from './ListEmployeeInactive';

export function ListEmployeeActives() {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [partnes, setPartnes] = useState<IPartnes[]>([]);

  const handleClickOpen = () => {
    setOpen(() => true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { employees, handleFilter, handleGetPartnes } = useManagementEmployee();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseFilter(filter: string) {
    if (typeof filter === 'string') {
      handleFilter(filter);
    }
    setAnchorEl(null);
  }

  const getPartnes = async () => {
    const data = await handleGetPartnes();
    setPartnes(data);
  };

  useEffect(() => {
    getPartnes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card style={{ padding: '10px' }}>
      <div
        style={{
          paddingBottom: '20px',
          paddingRight: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBar />
          <Button onClick={handleClickFilter}>
            <Iconify icon="codicon:list-filter" color={theme.palette.primary.main} />
            Filtrar
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleCloseFilter}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {filters.map((filter) => (
              <MenuItem key={filter.name} onClick={() => handleCloseFilter(filter.type)}>
                {filter.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Button
          onClick={() => handleClickOpen()}
          variant="contained"
          sx={{ width: '16rem' }}
          startIcon={<Iconify icon={'akar-icons:plus'} width={16} height={16} color="#FFFFFF" />}
        >
          Cadastrar usuário
        </Button>
      </div>
      <Box>
        {employees.length !== 0 ? (
          employees
            .filter((employee) => employee.active)
            .map((employee, index) => (
              <Accordion key={index} disableGutters elevation={0} square>
                <EmployeeSummary index={index} employee={employee} partnes={partnes} />
                <EmployeeDetail employee={employee} partnes={partnes} />
              </Accordion>
            ))
        ) : (
          <EmptyContent title="Não há usuários Ativos!" />
        )}
      </Box>
      <RegisterOrEditEmployee open={open} onClose={handleClose} partnes={partnes} />
    </Card>
  );
}
