import { createContext, ReactNode, useContext, useState } from 'react';

interface ModalLogData {
  open: boolean;
  currentUser: User | undefined;
  handleClickOpen: (user: User) => void;
  handleClose: () => void;
}

interface ModalLogProps {
  children: ReactNode;
}

interface User {
  id: string;
  name: string;
  cpf: string;
  contract?: string;
  photoURL: string;
  user_id?: string;
}

const ModalContext = createContext({} as ModalLogData);

export function ModalLogProvider({ children }: ModalLogProps) {
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>();

  const handleClickOpen = (user: User) => {
    setCurrentUser(user);

    setOpen(true);
  };

  const handleClose = () => {
    setCurrentUser(undefined);
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        currentUser,
        open,
        handleClickOpen,
        handleClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModalLogs = () => useContext(ModalContext);
