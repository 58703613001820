import { useContext } from 'react';
import { LogsContext } from 'src/contexts/LogsContext';

export const useLogs = () => {
  const context = useContext(LogsContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};
