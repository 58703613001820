import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
interface DependentSummaryProps {
  name: string;
  birthdate: string;
}

export function DependentSummary({ name, birthdate }: DependentSummaryProps) {
  const theme = useTheme();
  return (
    <Typography
      color={theme.palette.grey[600]}
      textTransform="uppercase"
      fontSize="13px"
      lineHeight="18px"
      marginLeft="15px"
    >
      <Grid container rowSpacing={1} marginBottom="5px">
        <Grid item xs={5}>
          {name}
        </Grid>
        <Grid item xs={6}>
          {new Intl.DateTimeFormat('pt-BR').format(new Date(birthdate))}
        </Grid>
      </Grid>
    </Typography>
  );
}
