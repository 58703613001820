import Router from 'src/routes';
import ThemeProvider from 'src/theme';
import { ChartStyle } from 'src/components/chart';
import ScrollToTop from 'src/components/ScrollToTop';
import { ProgressBarStyle } from 'src/components/ProgressBar';
import NotistackProvider from 'src/components/NotistackProvider';
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

// ----------------------------------------------------------------------

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-br');

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
          <ToastContainer />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
