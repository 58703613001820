import { createContext, ReactNode, useState, useEffect } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';
import axiosInstance from 'src/utils/axios';
import { Employee } from './ManagementEmployeeContext';

interface LogsProviderProps {
  children: ReactNode;
}

interface LogsContextData {
  employees: Employee[];
  open: boolean;
  currentEmployee: Employee | undefined;
  search: string;

  handleClickOpen: (employee: Employee) => void;
  handleClose: () => void;

  handleFilter: (filter: string) => void;
  handleSearch: (name: string) => void;
  getEmployees: () => void;
}

export const LogsContext = createContext({} as LogsContextData);

export function LogsProvider({ children }: LogsProviderProps) {
  const [filter, setFilter] = useState('');
  const [searchDebounced, search, setSearch] = useDebounce<string>('', 1000);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [open, setOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<Employee>();

  const handleClickOpen = (employee: Employee) => {
    setCurrentEmployee(employee);

    setOpen(true);
  };

  const handleClose = () => {
    setCurrentEmployee(undefined);
    setOpen(false);
  };

  function handleSearch(name: string) {
    setSearch(name);
  }

  function handleFilter(filter: string) {
    setFilter(filter);
  }

  async function getEmployees() {
    const response = await axiosInstance.get<Employee[]>(
      `/admin/employees?search=${searchDebounced}&filter=${filter}`
    );

    setEmployees(response.data);
  }

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounced, filter]);

  return (
    <LogsContext.Provider
      value={{
        employees,
        getEmployees,
        open,
        currentEmployee,
        handleClickOpen,
        handleClose,
        handleSearch,
        handleFilter,
        search,
      }}
    >
      {children}
    </LogsContext.Provider>
  );
}
