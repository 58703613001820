import * as Yup from 'yup';
import { Typography, Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axiosInstance from 'src/utils/axios';
import { PATH_AUTH } from 'src/routes/paths';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

type FormValuesProps = {
  email: string;
  cpf: string;
  afterSubmit?: string;
};

export const regexCamp = (value: any) => /^(?=.*[_])./gm.test(value);

export default function ResetPasswordEmail() {
  const isMountedRef = useIsMountedRef();

  const theme = useTheme();

  const LoginSchema = Yup.object().shape({
    cpf: Yup.string()
      .required('Digite um CPF')
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      ),
    email: Yup.string()
      .required('Digite o E-mail')
      .email('Digite um email válido!')
      .max(50, 'O e-mail deve ter no máximo 50 caracteres'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.post('/admin/employees/send-confirmation-email', {
        cpf: data.cpf.replace(/[^\d]+/g, ''),
        email: data.email,
      });
      toast.success('Você receberá um e-mail. Siga as instruções para gerar uma nova senha.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setTimeout(() => {
        window.location.href = PATH_AUTH.login;
      }, 6000);
    } catch (error) {
      console.error(error);
      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };
  return (
    <div>
      <Typography variant="h5" color={theme.palette.primary.main} style={{ marginBottom: '30px' }}>
        Esqueceu sua senha?
      </Typography>
      <Typography
        sx={{ color: 'text.secondary' }}
        fontSize={'16px'}
        lineHeight={'24px'}
        fontWeight={'400'}
        marginTop={'10x'}
        marginBottom={'20px'}
      >
        Por favor insira o endereço de e-mail associado com a sua conta, e nós iremos te mandar um
        e-mail com o link para a alteração de sua senha.
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Controller
          name="cpf"
          control={methods.control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputMask mask="999.999.999-99" value={value} onChange={onChange}>
              <TextField
                name="cpf"
                type="text"
                label="CPF"
                fullWidth
                error={Boolean(errors.cpf)}
                helperText={error?.message}
              />
            </InputMask>
          )}
        />
        <RHFTextField
          name="email"
          type="email"
          label="E-mail"
          style={{ margin: '10px 0', marginBottom: '20px' }}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Enviar e-mail
        </LoadingButton>
      </FormProvider>
    </div>
  );
}
