import { useClient } from 'src/contexts/ClientsContext';
import Iconify from './Iconify';
import { Button, TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

export default function SearchBar() {
  const { getClient } = useClient();
  const [value, setValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchClient = async () => {
    if (value.length < 11) {
      enqueueSnackbar('Por favor digite um CPF válido', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    } else {
      await getClient(value);
    }
    setValue('');
  };

  return (
    <form>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InputMask
          mask="999.999.999-99"
          value={value}
          onChange={(event) => {
            setValue(event.target.value.replace(/[^0-9]/g, ''));
          }}
          style={{
            marginRight: 10,
          }}
        >
          <TextField label="Pesquisar por CPF" size="medium" />
        </InputMask>
        <Button
          onClick={async () => handleSearchClient()}
          variant="contained"
          startIcon={<Iconify icon={'akar-icons:search'} width={16} height={16} color="#FFFFFF" />}
          size="large"
        >
          Pesquisar
        </Button>
      </div>
    </form>
  );
}
