import { Accordion, Card, CircularProgress, Typography, useTheme } from '@mui/material';
import { ClientSummary } from './ClientSummary';
import { ClientDetails } from './ClientDetails';
import SearchBar from 'src/components/SearchBar';
import EmptyContent from 'src/components/EmptyContent';
import { Client, useClient } from 'src/contexts/ClientsContext';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

export interface ListClientsProps {
  client: Client | undefined;
}

export function ListClientsActive({ client }: ListClientsProps) {
  // Hooks
  const { loading, messageError, setMessageError } = useClient();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (messageError !== '') {
      enqueueSnackbar('Cliente não encontrado!', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
      setMessageError('');
    }
  }, [messageError, enqueueSnackbar, setMessageError]);

  return (
    <Card style={{ padding: '20px' }}>
      <div
        style={{
          paddingBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <SearchBar />
        </div>
      </div>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
          <Typography
            color={theme.palette.grey[600]}
            textTransform="uppercase"
            fontWeight="700"
            fontSize="15px"
            lineHeight="18px"
            letterSpacing="1.2px"
            marginTop="20px"
          >
            Buscando dados do cliente...
          </Typography>
        </div>
      ) : client ? (
        <Accordion>
          <ClientSummary
            name={client.name}
            photoURL={''}
            cpf={client.cpf}
            birthdate={client.birthdate}
            index={0}
          />
          <ClientDetails contracts={client.contracts} />
        </Accordion>
      ) : (
        <EmptyContent title="Pesquise por um cliente na barra de pesquisa!" />
      )}
    </Card>
  );
}
