import { AccordionSummary, Box, Typography, useTheme, IconButton, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';

import { useSnackbar } from 'notistack';
import { Employee } from 'src/contexts/ManagementEmployeeContext';
import { useManagementEmployee } from 'src/hooks/useManagementEmployee';
import { useState } from 'react';
import { IPartnes, RegisterOrEditEmployee } from './RegisterOrEditEmployee';
import Avatar from 'src/components/Avatar';

interface EmployeeSummaryProps {
  employee: Employee;
  partnes?: IPartnes[];
  inactive?: boolean;
  photoURL?: string;
  index: number;
}

export function EmployeeSummary({
  employee,
  partnes,
  photoURL,
  index,
  inactive = false,
}: EmployeeSummaryProps) {
  const theme = useTheme();

  const { updateStatusEmployee, editEmployee } = useManagementEmployee();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const boxStyle = {
    backgroundColor: isHover ? theme.palette.background.neutral : 'white',
  };

  const handleClickOpen = () => {
    setOpen(() => true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onUpdateStatusEmployee() {
    try {
      await updateStatusEmployee(employee.id);
      window.location.reload();
    } catch (err) {
      enqueueSnackbar('Error ao inativar o usuário', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    }
  }

  async function onActiveEmployee() {
    try {
      await editEmployee({ ...employee, active: true });
      window.location.reload();
    } catch (err) {
      enqueueSnackbar('Error ao ativar o usuário', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    }
  }

  const handleAction = (del: boolean) => {
    const action = (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button color="secondary" size="small" onClick={() => closeSnackbar()}>
          Não
        </Button>

        <Button
          color="secondary"
          size="small"
          onClick={() => (del ? onUpdateStatusEmployee() : onActiveEmployee())}
        >
          Sim
        </Button>
      </Box>
    );
    return enqueueSnackbar(`Deseja ${del ? 'inativar' : 'ativar'} esse usuário?`, {
      variant: 'info',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      action,
    });
  };

  return (
    <AccordionSummary
      style={boxStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Avatar
          src={photoURL}
          alt={employee.name}
          color={photoURL ? 'default' : index % 2 === 0 ? 'primary' : 'secondary'}
        >
          <Iconify icon={'fa-regular:user'} />
        </Avatar>
        <Typography sx={{ width: '60%', paddingLeft: '20px' }}>
          <h4>{employee.name}</h4>
        </Typography>

        <Typography
          variant="subtitle1"
          fontSize="14px"
          fontWeight={700}
          color={theme.palette.primary.main}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          Ver detalhes do usuário
          <Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />
        </Typography>
      </Box>

      {!inactive && (
        <IconButton onClick={() => handleClickOpen()}>
          <Iconify icon={'humbleicons:pencil'} width={20} height={20} color="#184981" />
        </IconButton>
      )}

      {inactive ? (
        <IconButton sx={{ marginLeft: '4rem' }}>
          <Iconify
            onClick={() => handleAction(false)}
            icon={'ep:circle-check-filled'}
            width={20}
            height={20}
            color={theme.palette.success.dark}
          />
        </IconButton>
      ) : (
        <IconButton sx={{ marginLeft: '4rem' }}>
          <Iconify
            onClick={() => handleAction(true)}
            icon={'ep:circle-close-filled'}
            width={20}
            height={20}
            color={theme.palette.error.main}
          />
        </IconButton>
      )}

      <RegisterOrEditEmployee
        open={open}
        onClose={handleClose}
        edit={true}
        colaborator={employee}
        partnes={partnes}
      />
    </AccordionSummary>
  );
}
