import { useState } from 'react';

export function useModal(): [boolean, () => void, () => void] {
  const [open, setOpen] = useState(false);

  function handleOpenModal() {
    setOpen(true);
  }

  function handleCloseModal() {
    setOpen(false);
  }

  return [open, handleOpenModal, handleCloseModal];
}
