import { Box, Card, CardMedia, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { LoginForm } from 'src/sections/auth/login';
import AppVersion from 'src/version';

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundImage: 'url(/assets/illustrations/Familia-01_UHD.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0,0,0,0.3)',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const ContentLoginStyle = styled(Card)(({ theme }) => ({
  width: '80%',
  maxWidth: '350px',
  padding: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('lg')]: {
    padding: '20px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '30px auto',
  },
}));

// ----------------------------------------------------------------------

export default function HomePage() {
  const theme = useTheme();
  return (
    <ContentStyle>
      <ContentLoginStyle>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <CardMedia
            component="img"
            alt="logo"
            image="/assets/illustrations/marcaconceito.png"
            style={{ borderRadius: 8 }}
          />
          <Typography variant="h5" color={theme.palette.primary.main} mt={'10px'}>
            Seja bem-vindo(a) ao portal <br /> SEMPRE {process.env.REACT_APP_NAME}!
          </Typography>
          <Typography variant="body1" marginBottom={'20px'} marginTop={'10px'}>
            Entre com o seu CPF.
          </Typography>
        </Box>
        <LoginForm />
        <AppVersion mt={'10px'} />
      </ContentLoginStyle>
    </ContentStyle>
  );
}
