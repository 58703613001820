import { Box, Typography, useTheme } from '@mui/material';
import { Employee } from 'src/contexts/ManagementEmployeeContext';
import { phoneFormatter } from 'src/utils/formatPhone';
import { IPartnes, ROLES } from './RegisterOrEditEmployee';
import { cpf } from 'cpf-cnpj-validator';

interface EmployeeDetailProps {
  employee: Employee;
  partnes: IPartnes[];
}

export function EmployeeDetail({ employee, partnes }: EmployeeDetailProps) {
  const theme = useTheme();

  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="overline" sx={{ fontSize: '14px' }} color={theme.palette.grey[600]}>
        informações gerais
      </Typography>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[200],
          border: '1px solid #919EAB3D',
          borderRadius: '1rem',
          marginTop: '1.25rem',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1000px',
            padding: '1.25rem',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ minWidth: '160px', margin: '1rem 0' }}>
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              CPF
            </Typography>
            <Typography variant="body2">{cpf.format(employee.cpf)}</Typography>
          </Box>
          <Box sx={{ minWidth: '160px', margin: '1rem 0' }}>
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              Permissão
            </Typography>
            <Typography variant="body2">
              {ROLES.find((r) => r.value === employee.role)?.title}
            </Typography>
          </Box>
          <Box sx={{ minWidth: '160px', margin: '1rem 0' }}>
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              Contato
            </Typography>
            <Typography variant="body2">{phoneFormatter(employee.phone_number)}</Typography>
          </Box>
          <Box sx={{ minWidth: '160px', margin: '1rem 0' }}>
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              Email
            </Typography>
            <Typography variant="body2">{employee.email}</Typography>
          </Box>
          {employee.company_id && (
            <Box sx={{ minWidth: '160px', margin: '1rem 0' }}>
              <Typography variant="subtitle2" color={theme.palette.grey[600]}>
                Parceiro
              </Typography>
              <Typography variant="body2">
                {partnes.find((p) => p.id === employee.company_id)?.name}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
