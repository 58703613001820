import { Typography, useTheme, Box, Grid } from '@mui/material';
import { DependentSummary } from './DependentSummary';

export type Dependent = {
  name: string;
  birthdate: string;
};

export interface ListDependentsProps {
  dependents: Dependent[];
}

export function ListDependents({ dependents }: ListDependentsProps) {
  const theme = useTheme();

  return (
    <Box marginBottom="10px">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '5px 16px ',
          borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        }}
      >
        <Typography
          color={theme.palette.grey[600]}
          textTransform="uppercase"
          fontWeight="900"
          fontSize="13px"
          lineHeight="18px"
          letterSpacing="1.2px"
          margin="10px 0 10px"
        >
          {'DEPENDENTES'}
        </Typography>
      </div>
      <Typography
        color={theme.palette.grey[600]}
        textTransform="uppercase"
        fontWeight="700"
        fontSize="13px"
        lineHeight="18px"
        marginLeft="15px"
      >
        <Grid container rowSpacing={1} marginBottom="5px">
          <Grid item xs={5}>
            NOME
          </Grid>
          <Grid item xs={6}>
            DATA DE NASCIMENTO
          </Grid>
        </Grid>
      </Typography>
      {dependents
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((i, index) => (
          <DependentSummary key={index} name={i.name} birthdate={i.birthdate} />
        ))}
    </Box>
  );
}
