import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import {
  Box,
  Divider,
  TextField,
  Typography,
  useTheme,
  Container,
  Card,
  Switch,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import axiosInstance from 'src/utils/axios';
import { cpfFormatter } from 'src/utils/cpfFormatter';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TiWarning } from 'react-icons/ti';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/FormProvider';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import { regexPassword } from 'src/sections/@dashboard/employee/RegisterOrEditEmployee';

type FormValuesProps = {
  password?: string;
  phoneNumber: string;
  email: string;
};

export function Settings() {
  const { user } = useAuth();
  const { themeStretch } = useSettings();
  const [name, setName] = useState('');
  const [cpf, setCPF] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordText, setShowPasswordText] = useState(false);

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user) {
      setName(user.name);
      setCPF(user.cpf);
      setValue('phoneNumber', user.phone_number);
      setValue('email', user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const perfilSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('O número de telefone é obrigatório')
      .transform((value) => (value ? value.replace(/\D/g, '') : value)) // Remove caracteres não numéricos
      .min(11, 'O número de telefone não pode ter menos de 11 caracteres')
      .max(12, 'O número de telefone não pode ter mais de 11 caracteres'),

    email: Yup.string().email('Insira um email válido').required('O email é obrigatório'),

    password: showPassword
      ? Yup.string()
          .required('Digite uma senha')
          .matches(regexPassword, 'A senha não atende os requisitos de segurança!')
      : Yup.string(),
    confirmPassword: showPassword
      ? Yup.string()
          .required('')
          .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
      : Yup.string(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(perfilSchema),
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  /**
   * Manipula o processo de edição de um funcionário.
   *
   * @return {Promise<void>} A função não retorna nada.
   */

  async function submitEditEmployee(submit: FormValuesProps): Promise<void> {
    try {
      if (user) {
        const data = showPassword
          ? {
              id: user.id,
              email: submit.email,
              phone_number: submit.phoneNumber.replace(/[^0-9]/g, ''),
              password: submit.password,
            }
          : {
              id: user.id,
              email: submit.email,
              phone_number: submit.phoneNumber.replace(/[^0-9]/g, ''),
            };
        await axiosInstance.patch(`/admin/employees/${user.id}`, data);

        enqueueSnackbar('Usuário editado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      enqueueSnackbar('Preencha todos os campos', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    }
    reset();
  }

  return (
    <Page title="Meu Perfil">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Meu Perfil"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Perfil' }]}
        />
        <Divider sx={{ mb: 3 }} />

        <Card style={{ padding: '20px' }}>
          <Box sx={{ margin: '1.25rem 0', maxWidth: '600px' }}>
            <Typography variant="overline" color={theme.palette.grey[600]}>
              informações gerais
            </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(submitEditEmployee)}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Nome
                  </Typography>
                  <Box height="38px" display="flex" alignItems="center">
                    <Typography variant="body1" color={theme.palette.primary.main}>
                      {name}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ marginTop: '1.25rem', marginLeft: '5rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    CPF
                  </Typography>
                  <Box height="38px" display="flex" alignItems="center">
                    <Typography variant="body1" color={theme.palette.primary.main}>
                      {cpfFormatter(cpf || '00000000000')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Box sx={{ marginTop: '1.25rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Email
                  </Typography>
                  <RHFTextField name="email" type="text" size="small" />
                </Box>

                <Box sx={{ marginTop: '1.25rem', marginLeft: '4rem' }}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Contato
                  </Typography>
                  <Controller
                    name="phoneNumber"
                    control={methods.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <InputMask mask="(84) 9 9999-9999" value={value} onChange={onChange}>
                        <TextField
                          size="small"
                          name="phoneNumber"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          error={Boolean(errors.phoneNumber)}
                          helperText={error?.message}
                          sx={{ width: '270px' }}
                        />
                      </InputMask>
                    )}
                  />
                </Box>
              </Box>

              <Box sx={{ marginTop: '1.25rem', marginBottom: '1.3rem' }}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Deseja alterar a senha?
                </Typography>
                <Switch
                  checked={showPassword}
                  onChange={() => {
                    setShowPassword(!showPassword);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>

              {showPassword ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle2" color={theme.palette.primary.main}>
                        Nova senha
                      </Typography>
                      <RHFTextField
                        autoComplete="off"
                        sx={{ width: '270px' }}
                        size="small"
                        name="password"
                        type={showPasswordText ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPasswordText(!showPasswordText)}
                                edge="end"
                              >
                                <Iconify
                                  icon={showPasswordText ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color={theme.palette.primary.main}>
                        Confimar nova senha
                      </Typography>
                      <RHFTextField
                        autoComplete="off"
                        sx={{ width: '270px' }}
                        size="small"
                        name="confirmPassword"
                        type={showPasswordText ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPasswordText(!showPasswordText)}
                                edge="end"
                              >
                                <Iconify
                                  icon={showPasswordText ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      backgroundColor: theme.palette.warning.lighter,
                      color: theme.palette.warning.darker,
                      borderRadius: '10px',
                      padding: '15px 0',
                      margin: '10px 0',
                      marginTop: '30px',
                      fontSize: '12px',
                      fontWeight: '500',
                      textAlign: 'left',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '0 5px' }}>
                      <TiWarning size={'25px'} color={theme.palette.warning.main} />
                    </div>
                    <div>
                      A senha deve ter no mínimo 8 caracteres,e conter pelo menos:
                      <ul style={{ listStyle: 'none' }}>
                        <li>1 letra maiúscula;</li>
                        <li>1 letra minúscula;</li>
                        <li>1 número;</li>
                        <li>1 caractere especial (Exemplo: @#$%^&+!=?*-/).</li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : null}
              <LoadingButton
                sx={{ marginTop: '10px' }}
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Salvar meus dados
              </LoadingButton>
            </FormProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
