import { createContext, ReactNode, useState, useEffect } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';
import { useModal } from 'src/hooks/useModal';
import axiosInstance from 'src/utils/axios';

export interface Employee {
  id: string;
  name: string;
  cpf: string;
  email: string;
  phone_number: string;
  active: boolean;
  role: string;
  company_id: string | null;
}

interface AddEmployee {
  name: string;
  cpf: string;
  email: string;
  password: string;
  phone_number: string;
  role: string;
}

interface EditEmployee {
  id: string;
  name: string;
  cpf: string;
  email: string;
  password?: string;
  phone_number: string;
  role: string;
  active: boolean;
}

interface ManagementEmployeeProviderProps {
  children: ReactNode;
}

interface ManagementEmployeeData {
  isEditing: boolean;
  currentEmployee: string;
  employees: Employee[];

  addEmployee: (dataEmployee: AddEmployee) => void;
  editEmployee: (dataEmployee: EditEmployee) => void;
  updateStatusEmployee: (id: string) => void;
  getEmployeeById: (id: string) => Employee | undefined;
  getEmployees: () => void;

  search: string;
  handleSearch: (name: string) => void;

  filter: string;
  handleFilter: (filter: string) => void;

  openModal: boolean;
  handleOpenModalEmployee: (type: 'add' | 'edit', currentEmployeeId?: string) => void;
  handleCloseModalEmployee: () => void;
  handleGetPartnes: () => Promise<any>;
}

export const ManagementEmployeeContext = createContext({} as ManagementEmployeeData);

export function ManagementEmployeeProvider({ children }: ManagementEmployeeProviderProps) {
  const [openModal, handleOpenModal, handleCloseModal] = useModal();
  const [isEditing, setIsEditing] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState('');
  const [filter, setFilter] = useState('');

  const [searchDebounced, search, setSearch] = useDebounce<string>('', 1000);

  const [employees, setEmployees] = useState<Employee[]>([]);

  function handleOpenModalEmployee(type: 'add' | 'edit', currentEmployeeId?: string) {
    if (type === 'add') {
      handleOpenModal();
    }

    if (type === 'edit' && currentEmployeeId) {
      setIsEditing(true);
      setCurrentEmployee(currentEmployeeId);
      handleOpenModal();
    }
  }

  function handleCloseModalEmployee() {
    setIsEditing(false);
    setCurrentEmployee('');
    handleCloseModal();
  }

  async function addEmployee(dataEmployee: AddEmployee) {
    const { data } = await axiosInstance.post('/admin/employees', dataEmployee);

    setEmployees((oldEmployees) => [...oldEmployees, data]);
  }

  async function editEmployee(dataEmployee: EditEmployee) {
    const { data } = await axiosInstance.patch(`/admin/employees/${dataEmployee.id}`, dataEmployee);

    const copyEmployees = [...employees];

    const employeeIndex = copyEmployees.findIndex((employee) => employee.id === dataEmployee.id);

    copyEmployees[employeeIndex] = data;

    setEmployees(copyEmployees);
  }

  async function updateStatusEmployee(id: string) {
    await axiosInstance.patch(`/admin/employees/update-status/${id}`);

    const employeesFiltered = employees.filter((employee) => employee.id !== id);

    setEmployees(employeesFiltered);
  }

  function getEmployeeById(id: string): Employee | undefined {
    const employee = employees.find((employee) => employee.id === id);
    return employee;
  }

  function handleSearch(name: string) {
    setSearch(name);
  }

  function handleFilter(filter: string) {
    setFilter(filter);
  }

  async function getEmployees() {
    const response = await axiosInstance.get<Employee[]>(
      `/admin/employees?search=${searchDebounced}&filter=${filter}`
    );

    setEmployees(response.data);
  }

  const handleGetPartnes = async () => {
    const response = await axiosInstance.get(`/admin/companies`);
    return response.data;
  };

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounced, filter]);

  return (
    <ManagementEmployeeContext.Provider
      value={{
        filter,
        handleFilter,
        getEmployees,
        search,
        handleSearch,
        employees,
        addEmployee,
        updateStatusEmployee,
        editEmployee,
        getEmployeeById,
        openModal,
        handleOpenModalEmployee,
        handleCloseModalEmployee,
        isEditing,
        currentEmployee,
        handleGetPartnes,
      }}
    >
      {children}
    </ManagementEmployeeContext.Provider>
  );
}
