import { Typography, AccordionDetails, useTheme, Chip } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Key, ReactElement } from 'react';
import { ListDependents } from '../dependents/ListDependents';

interface SubtitleProps {
  content: ReactElement;
  label: string;
}

/**
 * Renderiza um componente de subtítulo com o conteúdo e rótulo fornecidos.
 *
 * @param {SubtitleProps} content - O conteúdo a ser exibido no subtítulo.
 * @param {SubtitleProps} label - O rótulo a ser exibido no subtítulo.
 * @return {JSX.Element} O componente de subtítulo renderizado.
 */

function Subtitle({ content, label }: SubtitleProps): JSX.Element {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
      <Typography
        variant={'subtitle2'}
        textTransform="none"
        color={theme.palette.grey[600]}
        fontWeight="900"
        fontSize="13px"
      >
        {label}
      </Typography>
      <Typography variant={'body2'} style={{ fontSize: 16 }}>
        {content}
      </Typography>
    </div>
  );
}

const ContainerContract = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  fontSize: '13px',
  padding: '15px',
  cursor: 'pointer',
  marginBottom: '20px',
}));

const Container = styled('div')(({ theme }) => ({
  borderRadius: '15px',
  backgroundColor: theme.palette.grey[200],
  paddingBottom: '10px',
  marginBottom: '15px',
}));

export interface IContract {
  contract_number: string;
  contract_type: string;
  name: string;
  status: string;
  unit: string;
  company: string;
  filial: string;
  dependents: [
    {
      name: string;
      birthdate: string;
    }
  ];
}

interface ClientDetailsProps {
  contracts: IContract[];
  onclick?: () => void;
}

/**
 * Renderiza os detalhes de um cliente.
 *
 * @param {ClientDetailsProps} props - As propriedades passadas para o componente.
 * @param {Array} props.contracts - A lista de contratos do cliente.
 * @return {JSX.Element} - Os detalhes do cliente renderizados.
 */

export function ClientDetails({ contracts }: ClientDetailsProps): JSX.Element {
  // Constantes
  const theme = useTheme();

  const status = [
    { status: '0', tip_contr: 'P', label: 'Contrato em Avaliação', color: '#ececec' },
    { status: '1', tip_contr: 'P', label: 'Contrato Ativo', color: '#9ce564' },
    { status: '2', tip_contr: 'P', label: 'Contrato Suspenso', color: '#79a8d4' },
    { status: '3', tip_contr: 'P', label: 'Contrato Encerrado', color: '#f25b5a' },
    { status: '4', tip_contr: 'P', label: 'Contrato Cancelado', color: '#7a7a7a' },
    { status: '5', tip_contr: 'P', label: 'Contrato Migrado', color: '#ebeb5e' },
    { status: '6', tip_contr: 'P', label: 'Ação Judicial', color: '#f0b657' },
    { status: '0', tip_contr: 'C', label: 'Contrato em Avaliação', color: '#ececec' },
    { status: '1', tip_contr: 'C', label: 'Contrato Ativo', color: '#9ce564' },
    { status: '2', tip_contr: 'C', label: 'Contrato Suspenso', color: '#7a7a7a' },
    { status: '3', tip_contr: 'C', label: 'Contrato Cancelado', color: '#f45555' },
    { status: '4', tip_contr: 'C', label: 'Contrato com Ação Judicial', color: '#f0b657' },
    { status: '5', tip_contr: 'C', label: 'Não Efetuar Cobrança', color: '#ebeb62' },
    { status: '7', tip_contr: 'C', label: 'Retomada de Jazigo', color: '#76a6d3' },
  ];

  return (
    <AccordionDetails>
      <Typography
        color={theme.palette.grey[600]}
        textTransform="uppercase"
        fontWeight="700"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="1.2px"
        marginBottom="20px"
      >
        informações gerais
      </Typography>
      {contracts.map((contract, index: Key | null | undefined) => {
        const color = status.find(
          (item) => item.tip_contr === contract.contract_type && item.status === contract.status
        )?.color;

        return (
          <Container key={index}>
            <ContainerContract>
              <Subtitle label="CONTRATO" content={<>{contract.contract_number}</>} />
              <Subtitle label="DESCRIÇÃO" content={<>{contract.name}</>} />
              <Subtitle label="FILIAL" content={<>{contract.unit}</>} />
              <Subtitle
                label="STATUS"
                content={
                  <Chip
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          color={theme.palette.grey[600]}
                          fontWeight="700"
                          fontSize="12px"
                          lineHeight="18px"
                          letterSpacing="1.2px"
                          marginRight="5px"
                          textTransform="uppercase"
                        >
                          {status.find((s) => s.status === contract.status)?.label}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: color,
                            marginLeft: 2,
                            borderRadius: 50,
                            width: 20,
                            height: 20,
                          }}
                        />
                      </div>
                    }
                  />
                }
              />
            </ContainerContract>
            {contract.dependents.length > 0 && <ListDependents dependents={contract.dependents} />}
          </Container>
        );
      })}
    </AccordionDetails>
  );
}
