import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import { useClient } from 'src/contexts/ClientsContext';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import useTabs from 'src/hooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { FilterModal } from 'src/sections/@dashboard/clients/FilterModal';
import { ListClientsActive } from 'src/sections/@dashboard/clients/ListClients';
import ChangePasswordFirstAccess from '../ChangePasswordFirstAccess';
import TermsOfUseFirstAccess from '../TermsOfUseFirstAccess';

export default function Clients() {
  const { themeStretch } = useSettings();
  const removeLoading = true;

  const { client } = useClient();

  const { user } = useAuth();

  const { currentTab, onChangeTab } = useTabs('Clientes Ativos');

  const ACCOUNT_TABS = [
    {
      value: 'Clientes',
      icon: <Iconify icon={'akar-icons:sun'} width={20} height={20} />,
      component: <ListClientsActive client={client} />,
    },
  ];

  return (
    <>
    {user?.accepts_use_terms === false ? (
      <TermsOfUseFirstAccess />
    ) : user?.change_password === true ? (
      <ChangePasswordFirstAccess />
    ) : (
    <Page title="Consulta de clientes">
      {removeLoading ? (
        <>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Consulta de clientes"
              links={[
                { name: 'Início', href: PATH_DASHBOARD.root },
                { name: 'Consulta de clientes' },
              ]}
            />
            <Divider />
            <Tabs value={currentTab} onChange={onChangeTab}>
              {ACCOUNT_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={tab.value}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Divider />
            <Box sx={{ mb: 5 }} />
            <ListClientsActive client={client} />
          </Container>
          <FilterModal />
        </>
      ) : (
        <LoadingScreen />
      )}
    </Page>
      )}
    </>
  );
}
