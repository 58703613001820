import { Typography } from '@mui/material';
import config from '../package.json';

const AppVersion = ({ ...ref }) => (
  <Typography variant="body1" sx={{ fontSize: 14, textAlign: 'center' }} {...ref}>
    {process.env.REACT_APP_ENVIRONMENT + ' v' + config.version}
  </Typography>
);
//
export default AppVersion;
