import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function getWeek() {
  const startWeek = dayjs().toDate().toISOString();

  const endWeek = dayjs(startWeek).subtract(7, 'day').toDate().toISOString();

  return { startWeek, endWeek };
}

export function getMonth() {
  const startMonth = dayjs().toDate().toISOString();

  const endMonth = dayjs(startMonth).subtract(30, 'day').toDate().toISOString();

  return { startMonth, endMonth };
}
