import { styled } from '@mui/material/styles';

import Logo from '../Logo';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

export default function SimpleHeader() {
  return (
    <HeaderStyle>
      <Logo />
    </HeaderStyle>
  );
}
