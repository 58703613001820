import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <img src="/logo/logo_sempre.png" alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
export function LogoCollapse({ disabledLink = false, sx }: Props) {
  const logoCollapse = (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <img src="/logo/logo_sempreCollapse.svg" alt="Logo" style={{ margin: '0 auto' }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logoCollapse}</>;
  }

  return <RouterLink to="/">{logoCollapse}</RouterLink>;
}
