import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} />;

const ICONS = {
  Clients: getIcon('clients'),
  historic: getIcon('historic'),
  logs: getIcon('logs'),
  access: getIcon('acess'),
  settings: getIcon('settings'),
};

type ROLES = 'ADMIN' | 'PARTNER';

function navConfig(ROLE: ROLES) {
  if (ROLE === 'ADMIN') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Clientes',
            path: PATH_DASHBOARD.general.clients,
            icon: ICONS.Clients,
          },
          {
            title: 'Logs e Auditorias',
            path: PATH_DASHBOARD.general.logs,
            icon: ICONS.logs,
            children: [
              {
                title: 'Usuários',
                path: PATH_DASHBOARD.general.logsEmployees,
              },
            ],
          },
          {
            title: 'Acessos',
            path: PATH_DASHBOARD.general.access,
            icon: ICONS.access,
          },

          {
            title: 'Perfil',
            path: PATH_DASHBOARD.general.settings,
            icon: ICONS.settings,
          },
        ],
      },
    ];
  }

  if (ROLE === 'PARTNER') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Clientes',
            path: PATH_DASHBOARD.general.clients,
            icon: ICONS.Clients,
          },
          {
            title: 'Perfil',
            path: PATH_DASHBOARD.general.settings,
            icon: ICONS.settings,
          },
        ],
      },
    ];
  }
}

export default navConfig;
