import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import Clients from 'src/pages/dashboard/Clients';
import { ManagementAccessEmployee } from 'src/pages/dashboard/ManagementAccessEmployee';
import HomePage from 'src/pages/Home';
import { LogsEmployee } from 'src/pages/dashboard/LogsEmployee';
import { Settings } from 'src/pages/dashboard/Settings';
import UpdatePassword from 'src/pages/auth/UpdatePassword';
import { TermOfUse } from 'src/pages/TermOfUse';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'updateAccount/new-password/*', element: <UpdatePassword /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'Clients', element: <Clients /> },
        {
          path: 'logs',
          children: [{ path: 'employees', element: <LogsEmployee /> }],
        },
        { path: 'access', element: <ManagementAccessEmployee /> },

        { path: 'Settings', element: <Settings /> },

        { path: 'permission-denied', element: <PermissionDenied /> },
        {
          path: 'term-of-use',
          element: <TermOfUse />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <HomePage />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));
const ResetPassword = Loadable(lazy(() => import('../pages/ChangePassword')));

// MAIN

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
