import { useEffect, ReactNode, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Divider,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  IconButton,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';
import Avatar from 'src/components/Avatar';
import axiosInstance from 'src/utils/axios';
import { formatDateWithTime } from 'src/utils/formatTime';
import { getWeek, getMonth } from 'src/utils/dayjs';
import { LoadingButton } from '@mui/lab';
import { useLogs } from 'src/hooks/useLogs';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '1080px',
    maxHeight: '720px',
  },

  '& .MuiDialogContent-root': {
    padding: '0px',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, borderBottom: '1px solid  rgba(145, 158, 171, 0.24)' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="eva:close-outline" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface ModalEmployeeLogsProps {
  handleClose: () => void;
  open: boolean;
}

const filterTypes = [
  {
    type: 'LIST_ALL',
    text: 'Listar todos',
  },
  {
    type: 'LOGIN_SUCCESSFULLY',
    text: 'Acesso bem sucedido',
  },
  {
    type: 'LOGIN_UNSUCCESSFULLY',
    text: 'Tentativas não bem sucedida',
  },
  {
    type: 'ADD_EMPLOYEE',
    text: 'Adicionar usuário',
  },
  {
    type: 'DELETE_EMPLOYEE',
    text: 'Excluir usuário',
  },
  {
    type: 'UPDATE_REGISTER_EMPLOYEE',
    text: 'Atualizar usuário',
  },
];

enum logTypes {
  LIST_ALL,
  LOGIN_SUCCESSFULLY,
  LOGIN_UNSUCCESSFULLY,
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  UPDATE_REGISTER_EMPLOYEE,
}

interface Log {
  id: string;
  browser: string;
  contract?: string;
  description: string;
  ip: string;
  created_at: Date;
  type: logTypes;
}

export function ModalEmployeeLogs({ handleClose, open }: ModalEmployeeLogsProps) {
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterType, setFilterType] = useState('');
  const [datesCheckItems, setDatesChecksItems] = useState('today');
  const [hasNextPage, setHasNextPage] = useState(true);

  const theme = useTheme();

  const { currentEmployee } = useLogs();

  const [logs, setLogs] = useState<Log[]>([]);

  function onClose() {
    setPage(1);
    handleClose();
  }

  function nextPage() {
    setPage((oldPage) => oldPage + 1);
  }

  function formatDate(date: string) {
    return date.split('T')[0];
  }

  async function getLogs(user_id: string, page: number) {
    let data = {
      page: page ? page : 1,
      start_date: startDate ? startDate : undefined,
      end_date: endDate ? endDate : undefined,
      filter_type: filterType ? filterType : undefined,
      last_item_id: logs.length > 0 ? logs[logs.length - 1].id : undefined,
    };

    const response = await axiosInstance.post(`/admin/employees/logs/${user_id}`, data);

    const { logs: logsResponse, hasNext } = response.data;
    setHasNextPage(hasNext);
    setLogs(logsResponse);
  }

  async function exportLogs() {
    try {
      const response = await axiosInstance.post('/admin/employees/generate-csv', {
        logs,
      });

      const blob = new Blob([response.data], { type: 'octet-stream' });

      const href = URL.createObjectURL(blob);

      const a = Object.assign(document.createElement('a'), {
        href,
        style: 'display:none',
        download: 'logs.csv',
      });

      document.body.appendChild(a);

      a.click();
      URL.revokeObjectURL(href);
      a.remove();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (currentEmployee && currentEmployee.id) {
      getLogs(currentEmployee.id, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmployee, page, startDate, endDate, filterType]);

  useEffect(() => {
    if (datesCheckItems === 'today') {
      const today = new Date().toISOString();
      const todayFormatted = formatDate(today);

      setStartDate(todayFormatted);
      setEndDate(todayFormatted);
    }

    if (datesCheckItems === 'week') {
      const { startWeek, endWeek } = getWeek();
      const startWeekFormatted = formatDate(endWeek);
      const endWeekFormatted = formatDate(startWeek);

      setStartDate(startWeekFormatted);
      setEndDate(endWeekFormatted);
    }

    if (datesCheckItems === 'month') {
      const { startMonth, endMonth } = getMonth();

      const startMonthFormatted = formatDate(endMonth);
      const endMonthFormatted = formatDate(startMonth);

      setStartDate(startMonthFormatted);
      setEndDate(endMonthFormatted);
    }
  }, [datesCheckItems]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Logs do usuário
      </BootstrapDialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              minWidth: '400px',
              borderRight: '1px solid  rgba(145, 158, 171, 0.24)',
              padding: '16px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ marginRight: '20px' }} src="" alt="alternativo" color="primary">
                <Iconify icon={'fa-regular:user'} />
              </Avatar>

              <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                <h4 style={{ textTransform: 'none' }}>{currentEmployee?.name}</h4>
              </div>
            </Box>

            <Box sx={{ marginTop: '20px' }}>
              <Typography
                color={theme.palette.grey[600]}
                textTransform="uppercase"
                fontWeight="700"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="1.2px"
                margin="20px 0"
              >
                FILTROS
              </Typography>

              <Box>
                <FormGroup>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '16px',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography color="primary" variant="button">
                        Data inicial
                      </Typography>

                      <TextField
                        size="small"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography color="primary" variant="button">
                        Data final
                      </Typography>

                      <TextField
                        size="small"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <RadioGroup
                    value={datesCheckItems}
                    onChange={(e) => setDatesChecksItems(e.target.value)}
                  >
                    <FormControlLabel
                      value="today"
                      control={<Radio />}
                      label="Mais recentes (hoje)"
                    />
                    <FormControlLabel
                      value="week"
                      control={<Radio />}
                      label="Apenas na última semana"
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio />}
                      label="Apenas no último mês"
                    />
                  </RadioGroup>
                  <Divider />

                  <Box sx={{ minWidth: 120, marginTop: '16px' }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterType}
                        label="Tipo"
                        onChange={(e) => setFilterType(e.target.value)}
                      >
                        {filterTypes.map((filterType) => (
                          <MenuItem value={filterType.type} key={filterType.type}>
                            {filterType.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </FormGroup>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              padding: '16px',
            }}
          >
            <Typography
              color={theme.palette.grey[600]}
              textTransform="uppercase"
              fontWeight="700"
              fontSize="12px"
              lineHeight="18px"
              letterSpacing="1.2px"
              margin="20px 0"
            >
              ATIVIDADES
            </Typography>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Button
                  variant="outlined"
                  sx={{ display: 'flex' }}
                  onClick={exportLogs}
                  disabled={logs.length === 0 ? true : false}
                >
                  <Iconify
                    icon={
                      logs.length === 0
                        ? 'ph:microsoft-excel-logo-fill'
                        : 'vscode-icons:file-type-excel'
                    }
                    width="22px"
                    height="22px"
                  />
                  Exportar CSV
                </Button>
              </Box>
            </Box>

            <Stepper
              orientation="vertical"
              sx={{
                maxHeight: '400px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '4px',
                },

                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },

                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#184981',
                  borderRadius: 2,
                },
              }}
            >
              {logs.map((log: Log) => (
                <Step key={log.id} active>
                  <StepLabel color="#244123">
                    {' '}
                    <h4>{log.description}</h4>
                  </StepLabel>
                  <StepContent>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                        <Typography
                          variant="caption"
                          fontSize="12px"
                          color={theme.palette.grey[600]}
                        >
                          {formatDateWithTime(log.created_at)}
                        </Typography>

                        <Typography
                          variant="caption"
                          fontSize="12px"
                          sx={{ marginLeft: '16px' }}
                          color={theme.palette.grey[600]}
                        >
                          browser - {log.browser}
                        </Typography>

                        <Typography
                          variant="caption"
                          fontSize="12px"
                          sx={{ marginLeft: '16px' }}
                          color={theme.palette.grey[600]}
                        >
                          ip - {log.ip}
                        </Typography>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
              {logs.length >= 10 && hasNextPage && (
                <LoadingButton sx={{ marginTop: '8px', textTransform: 'none' }} onClick={nextPage}>
                  Carregar mais
                </LoadingButton>
              )}
            </Stepper>
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
