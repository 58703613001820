import useAuth from '../hooks/useAuth';
import Avatar, { Props as AvatarProps } from './Avatar';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.name}
      color={user?.photoURL ? 'default' : 'secondary'}
      {...other}
    >
      <Iconify icon={'fa-regular:user'} />
    </Avatar>
  );
}
