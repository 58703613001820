import { Box, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useRef, useState } from 'react';
import axiosInstance from 'src/utils/axios';

import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { Terms } from 'src/components/Terms';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

export default function TermsOfUseFirstAccess() {
  const { user } = useAuth();
  const [open, setOpen] = useState<boolean>(!user?.accepts_use_terms); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleScroll = () => {
    const element = contentRef.current;
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setIsButtonDisabled(false);
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.patch(`admin/employees/${user?.id}`, {
        accepts_use_terms: true,
      });
      setOpen(false);
      toast.success('Termos de uso aceitos com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
    } catch (error) {
      toast.error('Falha ao aceitar os termos de uso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ m: 0, py: 2, pb: 3 }} id="customized-dialog-title">
        Termos de Uso
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ maxWidth: '700px', maxHeight: '674px', padding: '0px' }} ref={contentRef} onScroll={handleScroll}>
        <Box sx={{ width: '100%', marginTop: '8px', padding: '20px' }}>
          <Terms />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoadingButton type="button" variant="contained" loading={isLoading} disabled={isButtonDisabled} onClick={onSubmit}>
          Aceitar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
