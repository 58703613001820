import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/Iconify';
import { useManagementEmployee } from 'src/hooks/useManagementEmployee';

export function SearchBar() {
  const { search, handleSearch } = useManagementEmployee();

  return (
    <form>
      <IconButton>
        <Iconify icon="charm:search" margin="20px" marginRight="-40px" color="#184981" />
      </IconButton>
      <input
        type="text"
        placeholder="Buscar por usuário"
        value={search}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        style={{
          padding: '10px 10px 10px 35px',
          borderRadius: '7px',
          borderColor: '#919EAB52',
          width: '20vw',
        }}
      />
    </form>
  );
}
