import React, { useState } from 'react';
import { Typography, AccordionSummary, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Avatar from 'src/components/Avatar';
import { useLogs } from 'src/hooks/useLogs';
import { Employee } from 'src/contexts/ManagementEmployeeContext';

interface EmployeeSummaryProps {
  employee: Employee;
  photoURL?: string;
  index: number;
}

export function EmployeeSummary({ employee, photoURL = '', index }: EmployeeSummaryProps) {
  const theme = useTheme();

  const { handleClickOpen } = useLogs();

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const boxStyle = {
    backgroundColor: isHover ? theme.palette.background.neutral : 'white',
  };

  return (
    <AccordionSummary
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}
      style={boxStyle}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '80%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={photoURL}
            alt={employee.name}
            color={photoURL ? 'default' : index % 2 === 0 ? 'primary' : 'secondary'}
          >
            <Iconify icon={'fa-regular:user'} />
          </Avatar>

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <h4>{employee.name}</h4>
          </div>
        </div>

        <Typography
          variant="subtitle1"
          fontSize="14px"
          fontWeight={700}
          color={theme.palette.primary.main}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          textAlign="center"
          onClick={() => handleClickOpen(employee)}
        >
          <Iconify icon={'ant-design:pie-chart-outlined'} style={{ marginRight: '8px' }} />
          Ver logs do usuário
        </Typography>
      </div>
    </AccordionSummary>
  );
}
