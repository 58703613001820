import * as Yup from 'yup';
import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PATH_AUTH } from 'src/routes/paths';
import Page from 'src/components/Page';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import axiosInstance from 'src/utils/axios';
import { PasswordRequirement } from 'src/sections/auth/reset-password/PasswordRequirement';
import { toast } from 'react-toastify';
import SimpleHeader from 'src/components/header/SimpleHeader';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UpdatePassword() {
  const theme = useTheme();

  return (
    <Page title="Reset Password">
      <RootStyle>
        <SimpleHeader />
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              style={{ marginBottom: '30px' }}
            >
              Crie sua nova senha
            </Typography>
            <Typography
              sx={{ color: 'text.secondary' }}
              fontSize={'16px'}
              lineHeight={'24px'}
              fontWeight={'400'}
              marginTop={'10x'}
            >
              Insira a sua nova senha nos campos abaixo. Após a confirmação, você terá que fazer o
              login novamente com a nova senha.
            </Typography>
            <NewPassword />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

type FormValuesProps = {
  confirmPassword: string;
  password: string;
  afterSubmit?: string;
};

const regexPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm;
function NewPassword() {
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const NewPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Digite uma senha')
      .matches(regexPassword, 'A senha não atende os requisitos de segurança!'),
    confirmPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPasswordSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const token = window.location.pathname.split('&')[1];

      await axiosInstance.post('/admin/employees/forgot-password', {
        token: token,
        password: data.password,
      });
      toast.success('Senha Alterada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setTimeout(() => {
        window.location.href = PATH_AUTH.login;
      }, 4000);
    } catch (error) {
      console.error(error);
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };
  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          style={{ margin: '10px 0' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirmar senha"
          type={showPassword ? 'text' : 'password'}
          style={{ margin: '10px 0' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordRequirement />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Salvar nova senha
        </LoadingButton>
      </FormProvider>
    </div>
  );
}
