import { Link, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Titile = styled('div')(() => ({
  textAlign: 'center',
  width: '100%',
  marginBottom: '10px',
}));

const Text = styled(Typography)(() => ({
  textAlign: 'justify',
  marginBottom: '10px',
}));

// ----------------------------------------------------------------------

export function Terms() {
  const theme = useTheme();

  return (
        <>
        <Titile>
            <Typography variant="h6">
                TERMO DE USO PELO TRATAMENTO DE DADOS PESSOAIS, SIGILO E EXCLUSIVIDADE.
            </Typography>
        </Titile>
        <Text variant="body2">
              Este Termo de Uso pelo Tratamento de Dados Pessoais, Sigilo e Exclusividade é um
              contrato celebrado entre você (OPERADOR), qualificado no momento de seu cadastro como
              Usuário do “Sempre Parceiros”, e SAFRA SAO FRANCISCO ASSISTENCIA FUNERARIA LTDA
              (CONTROLADOR)., sociedade empresária inscrita no CNPJ/ME sob o nº 70.313.051/0001-59,
              com sede à Avenida Presidente Bandeira, nº 487, Alecrim, Natal/RN, CEP: 59.031-200,
              Natal/RN, para fins de conferir o devido tratamento adequado aos dados pessoais
              fornecidos por esta através de seu portal digital, na melhor forma da Lei nº
              13.709/2018.
        </Text><Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 1ª – DAS DEFINIÇÕES
        </Text>
        <Text variant="body2">
              Para efeitos da Lei 13.709/18 – LGPD, considera-se: <br />
              <br />
              a. DADO PESSOAL: informação relacionada a pessoa natural identificada ou
              identificável; <br />
              <br />
              b. DADO PESSOAL SENSÍVEL: dado pessoal sobre origem racial ou étnica, convicção
              religiosa, opinião política, filiação a sindicato ou a organização de caráter
              religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado
              genético ou biométrico, quando vinculado a uma pessoa natural; <br />
              <br />
              c. TITULAR DOS DADOS: pessoa natural a quem se referem os dados pessoais que são
              objeto de tratamento; <br />
              <br />
              d. CONTROLADOR: pessoa natural ou jurídica, de direito público ou privado, a quem
              competem as decisões referentes ao tratamento de dados pessoais; <br />
              <br />
              e. OPERADOR: pessoa natural ou jurídica, de direito público ou privado, que realiza o
              tratamento de dados pessoais em nome do controlador; <br />
              <br />
              f. AGENTES DE TRATAMENTO: o controlador e o operador; <br />
              <br />
              g. ENCARREGADO (DPO): pessoa indicada pelo controlador e operador para atuar como
              canal de comunicação entre o controlador, os titulares dos dados e a Autoridade
              Nacional de Proteção de Dados (ANPD); <br />
              <br />
              h. TRATAMENTO: toda operação realizada com dados pessoais, como as que se referem a
              coleta, produção, recepção, classificação, utilização, acesso, reprodução,
              transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
              avaliação ou controle da informação, modificação, comunicação, transferência, difusão
              ou extração; <br />
              <br />
              i. CONSENTIMENTO: manifestação livre, informada e inequívoca pela qual o titular
              concorda com o tratamento de seus dados pessoais para uma finalidade determinada.
          </Text>
          <Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 2ª – DAS DECLARAÇÕES.
          </Text>
          <Text variant="body2">
              Ao aderir de forma plena e irrestrita ao presente Termo de Uso, você, OPERADOR,
              declara sem quaisquer reservas que:
              <br />
              <br />
              a) você, OPERADOR, é pessoa física ou jurídica é plenamente habilitada para quaisquer
              atos da vida civil, nos termos da legislação que lhe seja aplicável na jurisdição de
              sua residência;
              <br />
              <br />
              b) a sua atuação junto ao CONTROLADOR visa a um objeto lícito e possível;
              <br />
              <br />
              c) a sua atuação junto ao CONTROLADOR é feita de forma livre e desimpedida, sem
              qualquer situação de coação, pressão por parte de qualquer pessoa, erro, simulação ou
              estado de perigo;
              <br />
              <br />
              d) você, OPERADOR, estava presente no ato de criação ou atualização de seu perfil de
              usuário no “Sempre Parceiros”, provido pelo CONTROLADOR, em especial no ato de marcar
              a opção "Li e estou de acordo com o Termo de Uso pelo Tratamento de Dados Pessoais,
              Sigilo e Exclusividade”, e recebeu confirmação imediata de seu perfil de usuário;
              <br />
              <br />
              e) ao realizar o acesso a Área do Cliente, o Usuário se submeterá automaticamente às
              regras e condições estabelecidas neste Termo de Uso;
              <br />
              <br />
              f) os nomes, CPFs, números de telefone e e-mail informados no ato da criação de seu
              perfil de usuário e em atualizações posteriores do cadastro estão ativos e são
              monitorados por você, OPERADOR, com frequência adequada;
              <br />
              <br />
              g) você, OPERADOR, cumpriu e cumpre todos os nossos procedimentos necessários de
              validação e verificação de identidade e segurança;
              <br />
              <br />
              h) você, OPERADOR, nunca cedeu, não cede, e não cederá os seus dados de acesso ao
              “Sempre Parceiros” a terceiros, e não se utiliza de dados de acesso de terceiros.
          </Text>
          <Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 3ª – DOS PRAZOS E DA VIGÊNCIA{' '}
          </Text>
          <Text variant="body2">
              Este Termo de Uso é celebrado por prazo indeterminado, entrando em vigor na data de
              sua aceitação por você, OPERADOR, de forma presencial ou eletrônica.
              <br />
              <br />
              3.1 O CONTROLADOR poderá modificar, adicionar ou remover quaisquer cláusulas ou
              condições deste Termo de Uso, substituindo imediatamente a versão disponível no site
              pela versão mais atualizada, informando o OPERADOR da mudança por qualquer dos canais
              de comunicação disponíveis.
          </Text>
          <Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 4ª – DA POLÍTICA DE PRIVACIDADE{' '}
          </Text>
          <Text variant="body2">
              Nas operações de coleta, armazenamento, guarda e tratamento de registros, de dados
              pessoais, de dados pessoais sensíveis ou de comunicações, você, OPERADOR, garante ao
              CONTROLADOR que respeita a legislação brasileira e os direitos à privacidade, à
              proteção dos dados pessoais e ao sigilo das comunicações privadas e dos registros.
              <br />
              <br />
              4.1 O OPERADOR garante ao CONTROLADOR que os dados cadastrais da pessoa natural a que
              se referem (TITULAR) a que tiver acesso ou que lhes forem confiados sob qualquer
              modalidade não serão vendidos, trocados ou divulgados para terceiros, exceto sob ordem
              expressa do CONTROLADOR e com consentimento escrito do TITULAR, nos casos em que essas
              informações sejam necessárias para os sacramentos e para os serviços prestados aos
              fiéis e à comunidade, em especial para cobrança ou para participação em serviços e
              eventos solicitados pelo TITULAR.
              <br />
              <br />
              4.2 O OPERADOR deve colaborar para que as informações pessoais do TITULAR sejam
              tratadas com o nível de proteção legalmente exigível para garantir sua segurança e
              evitar alterações, perdas, tratamentos ou acessos não autorizados.
              <br />
              <br />
              4.3 Você, OPERADOR, deverá diligenciar e tomar todas as providências necessárias para
              impedir a divulgação de qualquer informação, bem como para evitar que tais informações
              confidenciais caiam em domínio público, sendo-lhe absolutamente vedado divulgar sua
              senha ou qualquer de seus dados de acesso a terceiros, ainda que colegas de trabalho,
              parentes ou amigos.
              <br />
              <br />
              4.4 O OPERADOR reconhece que o dever de sigilo se aplica de forma especial e reforçada
              em relação aos dados pessoais, religiosos, financeiros, bancários e tributários de
              clientes do CONTROLADOR a que eventualmente tem, teve ou terá acesso no exercício de
              suas atividades, tendo o OPERADOR dever reforçado de preservação absoluta de tais
              dados, sendo-lhe vedado utilizá-los em benefício ou prejuízo seu ou de terceiros.
              <br />
              <br />
              4.5 Embora você, OPERADOR, possa requerer a rescisão de suas relações funcionais com o
              CONTROLADOR e o cancelamento de seu perfil de usuário do “Sempre Parceiros”, o
              CONTROLADOR terá de reter parte ou todas as informações prestadas em determinados
              casos, com a finalidade de resolver disputas ou reclamações, detectar problemas ou
              incidências e solucioná-los, e cumprir as disposições legais e contratuais aplicáveis;
              portanto, embora o CONTROLADOR comprometa-se a empregar os melhores esforços, poderá
              ocorrer que nem todas as informações pessoais sejam definitivamente eliminadas.
              <br />
              <br />
              4.6 Você, OPERADOR, ao cadastrar ou acessar o seu perfil de usuário no Sistema Vila,
              atuar junto ao CONTROLADOR, imediatamente concede consentimento, nos termos do art.
              7º, inciso I, e do art. 8º da Lei nº 13.709/2018, para que o CONTROLADOR proceda com
              quaisquer formas legalmente permitidas de tratamento de seus dados pessoais, para as
              finalidades e nos parâmetros estipulados neste Termo de Uso.
              <br />
              <br />
              4.7 Você, OPERADOR, desde já reconhece que conta com pleno conhecimento de todos os
              direitos do TITULAR dos dados pessoais, nos termos do art. 18 da Lei nº 13.709/2018.
              <br />
              <br />
              4.8 O tratamento de dados pessoais de crianças e de adolescentes deverá ser realizado
              em seu melhor interesse, nos termos deste Termo de Uso e da legislação pertinente,
              sendo sua obrigação, OPERADOR, coletar o consentimento específico e em destaque para o
              tratamento de dados pessoais de crianças junto ao pai, mãe ou responsável legal.
              <br />
              <br />
              4.9 O CONTROLADOR deverá acessar a Área do Usuário através dos dados fornecidos pelo
              Sempre Parceiros, sendo vedado o compartilhamento de seus dados de acesso a terceiros
              e a utilização dos dados de outro usuário.
          </Text>
          <Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 5ª – DO SIGILO.
          </Text>
          <Text variant="body2">
              Além do dever de sigilo específico em relação a dados pessoais e dados pessoais
              sensíveis, abordado na Cláusula anterior, o OPERADOR compromete-se a manter sigilo
              pleno de todas as informações de qualquer natureza a que tenha acesso em suas relações
              com o CONTROLADOR, não utilizando tais informações confidenciais em proveito próprio
              ou alheio.
              <br />
              <br />
              5.1 As informações somente poderão ser reveladas a terceiros mediante consentimento
              prévio e por escrito do CONTROLADOR, ou em caso de determinação judicial ou de
              autorização expressa do cliente quanto às informações por ele geradas e detidas,
              hipótese em que o OPERADOR deverá informar de imediato, por escrito, ao CONTROLADOR
              para que esta procure obstar e afastar a obrigação de revelar as informações.
              <br />
              <br />
              5.2 O OPERADOR deverá diligenciar e tomar todas as providências necessárias para
              proteger e impedir a divulgação de qualquer informação, bem como para evitar que tais
              informações confidenciais caiam em domínio público; o OPERADOR não poderá divulgar de
              forma alguma as informações a terceiros, nem poderá utilizá-las direta ou
              indiretamente para a obtenção de qualquer patente, direitos de propriedade intelectual
              ou para quaisquer outros propósitos que não a operação a proveito do CONTROLADOR, a
              não ser que haja expressa autorização por escrito de uma para outra neste sentido.
              <br />
              <br />
              5.3 O OPERADOR deverá restringir, na medida do possível, o acesso às informações tão
              somente aos colaboradores que estiverem diretamente envolvidos na operação realizada,
              na medida do estritamente necessário.
              <br />
              <br />
              5.4 O OPERADOR não poderá tirar cópias, nem tampouco reproduzir por quaisquer meios as
              informações a que tiver acesso, no todo ou em parte, salvo se houver autorização
              expressa do CONTROLADOR para tanto.
              <br />
              <br />
              5.5 O OPERADOR, neste ato, reconhece e aceita que, na hipótese de violação a quaisquer
              das cláusulas deste termo, estará sujeito a todas as sanções e penalidades
              estabelecidas nos termos da legislação brasileira, nos campos administrativo, civil e
              penal, especialmente quanto ao pagamento de indenização por perdas e danos causados em
              virtude de tal violação, inclusive nos termos do art. 482, alíneas c e g, da
              Consolidação das Leis do Trabalho.
          </Text>
          <Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 6ª – DA EXCLUSIVIDADE E DA NÃO-COMPETIÇÃO.
          </Text>
          <Text variant="body2">
              Tendo em vista o caráter de acesso a informação comercial, industrial e intelectual
              sensível que marca as relações passadas, presentes e futuras do OPERADOR junto ao
              CONTROLADOR, as partes reconhecem que é de sua essência a cláusula de não competição
              do OPERADOR contra o CONTROLADOR a partir da assinatura do presente instrumento,
              incluindo, mas não se limitando às disposições desta cláusula.
              <br />
              <br />
              6.1 Compromete-se o OPERADOR a não abrir empresa concorrente com a atividade econômica
              do CONTROLADOR, incluindo-se seus sócios e representantes legais, por todo o período
              do seu relacionamento com o CONTROLADOR e pelo prazo de 5 (cinco) anos após a sua
              cessação, nos moldes do previsto no art. 1.147, caput e parágrafo único, do Código
              Civil, consistindo essa vedação na proibição de quaisquer atividades do OPERADOR que,
              direta ou indiretamente, assediem, abordem, atendam, vendam, contratem ou de qualquer
              modo desviem em proveito próprio ou alheio clientela da carteira do CONTROLADOR.
              <br />
              <br />
              6.2 As informações reveladas pelo CONTROLADOR ao OPERADOR, nos termos do presente
              acordo, permanecerão sendo única e exclusivamente de propriedade do CONTROLADOR, de
              modo que nenhuma disposição do presente termo deve ser interpretada como licença ou
              transferência de patentes, marcas, direitos autorais, ou direitos relativos a segredos
              industriais, comerciais ou intelectuais.
              <br />
              <br />
              6.3 O OPERADOR deverá devolver todo material que contenha informações por escrito ou
              por qualquer outra forma, ao término do relacionamento entre as partes ou sempre que
              for requisitado pelo CONTROLADOR, sendo que o OPERADOR não poderá reter quaisquer
              cópias, resumos ou anotações de informações, salvo se houver prévio consentimento por
              escrito do CONTROLADOR neste sentido.
          </Text>
          <Text variant="body2" style={{ fontWeight: 'bold' }}>
              CLÁUSULA 7ª – DAS DISPOSIÇÕES GERAIS.
          </Text>
          <Text variant="body2">
              O presente Termo constitui doravante o meio apropriado para regular o Tratamento de
              Dados Pessoais fornecidos pelo CONTROLADOR e substitui todos previamente celebrados
              entre a CONTROLADOR e o OPERADOR para esta finalidade, se houver.
              <br />
              <br />
              7.1 Nenhuma das Partes poderá, sem o prévio consentimento escrito da outra Parte,
              delegar, transferir, cobrar ou tratar de outra maneira este Contrato ou qualquer dos
              direitos relacionados a ele.
              <br />
              <br />
              7.2 Usuários menores de 18 anos ou que necessitem de representação, na forma da lei,
              deverão realizar o acesso a Área do Usuário com a assistência dos pais ou de seus
              representantes legais.
              <br />
              <br />
              7.3 O Sempre Parceiros poderá cancelar ou suspender temporariamente a Área do Usuário,
              bem como alterar, incluir ou excluir seus conteúdos e funcionalidades, a qualquer
              momento, independentemente de prévio aviso ao Usuário. Poderá, igualmente, a qualquer
              tempo, modificar este Termo de Uso, disponibilizando a versão mais recente para
              consulta.
              <br />
              <br />
              7.4 Toda e qualquer inserção ou alteração de dados ou outra ação executada pelo
              Usuário durante o uso da Área do Usuário será de sua exclusiva e integral
              responsabilidade, estando isento a Empresa Vila de quaisquer reclamações, prejuízos ou
              perdas e danos em decorrência de tais ações ou manifestações.
              <br />
              <br />
              7.5 A Empresa Vila poderá bloquear e cancelar o acesso a Área do Usuário quando
              verificar que o Usuário praticou alguma conduta que viole o ordenamento jurídico
              brasileiro ou contrarie as regras deste Termo de Uso.
              <br />
              <br />
              7.6 O Sempre Parceiros se exime de toda e qualquer responsabilidade pelos danos e
              prejuízos de qualquer natureza que possam decorrer do acesso, interceptação,
              eliminação, alteração, modificação ou manipulação, por terceiros autorizados ou não,
              dos dados do usuário durante a utilização da Área do Usuário.
              <br />
              <br />
              7.7 O usuário autoriza a Empresa Vila, ou terceiros indicados por este, a realizar o
              tratamento dos dados pessoais fornecidos durante o uso da Área do Usuário, por prazo
              indeterminado, para fins de cadastro e de uso do sistema, bem como para fins de envio
              de avisos, materiais publicitários e newsletters, entre outros.
              <br />
              <br />
              7.8 O ENCARREGADO indicado pela Empresa Vila para atuar como canal de comunicação em
              temas voltados para dados pessoais, poderá ser contatado da seguinte forma:
          </Text>
          <Text variant="body2">
              <strong>(DPO – DATA PROTECTION OFFICER)</strong>
              <br />
              <strong>
                  [
                  <Link href={'tel:0800 402 2002'} variant="body2" fontWeight="bold">
                      0800 402 2002
                  </Link>
                  ] - [
                  <Link href={'mailto:dpo@empresavila.com.br'} variant="body2" fontWeight="bold">
                      dpo@empresavila.com.br
                  </Link>
                  ]
              </strong>
              <br />
              Para maiores informações, consulte nossa política de privacidade de dados disponível
              no site:{' '}
              <Link
                  href={'https://planosempre.com.br/politica-de-privacidade/'}
                  target={'_blank'}
                  color={theme.palette.primary.main}
                  variant="body2"
                  fontStyle="italic"
              >
                  https://planosempre.com.br/politica-de-privacidade/
              </Link>
          </Text>
          <Text variant="body2">
              7.9 Este Termo será regido e interpretado de acordo com as leis da República
              Federativa do Brasil e as Partes submetem-se à jurisdição exclusiva dos tribunais da
              cidade de Natal, estado do Rio Grande do Norte, para dirimir todas as dúvidas que
              possam surgir a partir de qualquer das cláusulas deste Termo, com a exclusão de
              qualquer outro, por mais privilegiado que seja.
          </Text>
          </>
  );
}