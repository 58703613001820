import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'src/hooks/useDebounce';
import { useModal } from 'src/hooks/useModal';
import { IContract } from 'src/sections/@dashboard/clients/ClientDetails';
import axiosInstance from 'src/utils/axios';

interface ClientsContextProps {
  children: ReactNode;
}

export interface Client {
  name: string;
  cpf: string;
  birthdate: string;
  contracts: IContract[];
}

interface ClientsProviderProps {
  setClient: Dispatch<SetStateAction<Client | undefined>>;
  listActives: {
    id: string;
    name: string;
    cpf: string;
    presignedUrl: string;
    phone_number: string;
    email: string;
    birthdate: string;
    zip_code: string;
    adress: string;
    house_number: string;
    city: string;
    state: string;
    marital_status: string;
    contract_number: string;
    active: boolean;
  }[];
  setListActives: Dispatch<
    SetStateAction<
      {
        id: string;
        name: string;
        cpf: string;
        presignedUrl: string;
        phone_number: string;
        email: string;
        birthdate: string;
        zip_code: string;
        adress: string;
        house_number: string;
        city: string;
        state: string;
        marital_status: string;
        contract_number: string;
        active: boolean;
      }[]
    >
  >;
  listInactives: {
    id: string;
    name: string;
    cpf: string;
    presignedUrl: string;
    phone_number: string;
    email: string;
    birthdate: string;
    zip_code: string;
    adress: string;
    house_number: string;
    city: string;
    state: string;
    marital_status: string;
    contract_number: string;
    active: boolean;
  }[];
  setListInactives: Dispatch<
    SetStateAction<
      {
        id: string;
        name: string;
        cpf: string;
        presignedUrl: string;
        phone_number: string;
        email: string;
        birthdate: string;
        zip_code: string;
        adress: string;
        house_number: string;
        city: string;
        state: string;
        marital_status: string;
        contract_number: string;
        active: boolean;
      }[]
    >
  >;
  searchClients: string;
  setSearchClients: Dispatch<SetStateAction<string>>;
  value: string;
  debouncedValue: string;
  setValue: Dispatch<string>;
  client: Client | undefined;
  openModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleFilters: (data: Filters) => void;
  city: string;
  state: string;
  getClient: (data: string) => Promise<void>;
  loading: boolean;
  messageError: string;
  setMessageError: Dispatch<SetStateAction<string>>;
}

interface Filters {
  city: string;
  state: string;
  initialRange: string;
  finalRange: string;
}

export const ClientsContext = createContext({} as ClientsProviderProps);

export const ClientsProvider = ({ children }: ClientsContextProps) => {
  const location = useLocation();
  const [debouncedValue, value, setValue] = useDebounce<string>('', 1000);
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [openModal, handleOpenModal, handleCloseModal] = useModal();
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [searchClients, setSearchClients] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [listActives, setListActives] = useState([
    {
      id: '',
      name: '',
      cpf: '',
      presignedUrl: '',
      phone_number: '',
      email: '',
      birthdate: '',
      zip_code: '',
      adress: '',
      house_number: '',
      city: '',
      state: '',
      marital_status: '',
      contract_number: '',
      active: true,
    },
  ]);
  const [listInactives, setListInactives] = useState([
    {
      id: '',
      name: '',
      cpf: '',
      presignedUrl: '',
      phone_number: '',
      email: '',
      birthdate: '',
      zip_code: '',
      adress: '',
      house_number: '',
      city: '',
      state: '',
      marital_status: '',
      contract_number: '',
      active: true,
    },
  ]);

  function handleFilters(data: Filters) {
    setCity(data.city);
    setState(data.state);
  }

  const getClient = async (cpf: string) => {
    setLoading(true);
    await axiosInstance
      .get<Client>(`/admin/employees/search-clients/${cpf}`)
      .then((response) => {
        setClient(response.data);
        setMessageError('');
      })
      .catch((error) => {
        setClient(undefined);
        setLoading(false);
        if (error.statusCode === 400) {
          setMessageError(error.message);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    if (location.pathname !== '') {
      setClient(undefined);
    }
  }, [location]);

  return (
    <ClientsContext.Provider
      value={{
        messageError,
        setMessageError,
        loading,
        city,
        state,
        handleFilters,
        openModal,
        handleOpenModal,
        handleCloseModal,
        client,
        setClient,
        listActives,
        setListActives,
        listInactives,
        setListInactives,
        searchClients,
        setSearchClients,
        value,
        setValue,
        debouncedValue,
        getClient,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export const useClient = () => useContext(ClientsContext);
