import { ManagementEmployeeContext } from '../contexts/ManagementEmployeeContext';
import { useContext } from 'react';

export const useManagementEmployee = () => {
  const context = useContext(ManagementEmployeeContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};
