import { Container, Tab, Box, Tabs } from '@mui/material';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import useTabs from 'src/hooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ListEmployeeActive } from 'src/sections/@dashboard/logs/ListEmployees';
import { ListEmployeeInactive } from 'src/sections/@dashboard/logs/ListEmployeesInactive';

import { useLogs } from 'src/hooks/useLogs';
import { ModalEmployeeLogs } from 'src/sections/@dashboard/logs/ModalEmployeeLogs';

export function LogsEmployee() {
  const { themeStretch } = useSettings();
  const { open: openModalEmployee, handleClose: handleCloseModalEmployee } = useLogs();

  const { currentTab, onChangeTab } = useTabs('Usuários Ativos');

  const ACCOUNT_TABS = [
    {
      value: 'Usuários Ativos',
      icon: <Iconify icon={'akar-icons:sun'} width={20} height={20} />,
      component: <ListEmployeeActive />,
    },
    {
      value: 'Usuários Inativos',
      icon: <Iconify icon={'bx:moon'} width={20} height={20} />,
      component: <ListEmployeeInactive />,
    },
  ];

  return (
    <Page title="Consulta de clientes">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Gerenciar Logs e Auditorias"
          links={[
            { name: 'Início', href: PATH_DASHBOARD.root },
            { name: 'Gerenciar Logs e Auditorias' },
            { name: 'Usuários' },
          ]}
        />

        <Tabs value={currentTab} onChange={onChangeTab}>
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
      <ModalEmployeeLogs open={openModalEmployee} handleClose={handleCloseModalEmployee} />
    </Page>
  );
}
