// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    clients: path(ROOTS_DASHBOARD, '/clients'),
    editClient: path(ROOTS_DASHBOARD, '/clients/editClient'),
    logs: path(ROOTS_DASHBOARD, '/logs'),
    logsClients: path(ROOTS_DASHBOARD, '/logs/clients'),
    logsEmployees: path(ROOTS_DASHBOARD, '/logs/employees'),
    access: path(ROOTS_DASHBOARD, '/access'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    userPolicy: path(ROOTS_DASHBOARD, '/term-of-use'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
};
