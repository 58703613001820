import { Accordion, Card, useTheme, Box, Button, Menu, MenuItem } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { EmployeeDetail } from './EmployeeDetail';
import { EmployeeSummary } from './EmployeeSummary';
import { SearchBar } from './SearchBar';
import React, { useEffect, useState } from 'react';
import { useManagementEmployee } from 'src/hooks/useManagementEmployee';
import EmptyContent from 'src/components/EmptyContent';
import { IPartnes } from './RegisterOrEditEmployee';

export const filters = [
  {
    name: 'ADMINISTRADOR',
    type: 'ADMIN',
  },
  {
    name: 'PARCEIRO',
    type: 'PARTNER',
  },
  {
    name: 'LISTAR TODOS',
    type: '',
  },
];

export function ListEmployeeInactive() {
  const theme = useTheme();
  const [partnes, setPartnes] = useState<IPartnes[]>([]);

  const { employees, handleFilter, handleGetPartnes } = useManagementEmployee();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseFilter(filter: string) {
    if (typeof filter === 'string') {
      handleFilter(filter);
    }
    setAnchorEl(null);
  }

  const getPartnes = async () => {
    const data = await handleGetPartnes();
    setPartnes(data);
  };

  useEffect(() => {
    getPartnes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card style={{ padding: '10px' }}>
      <div
        style={{
          paddingBottom: '20px',
          paddingRight: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBar />
          <Button onClick={handleClickFilter}>
            <Iconify icon="codicon:list-filter" color={theme.palette.primary.main} />
            Filtrar
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleCloseFilter}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {filters.map((filter) => (
              <MenuItem key={filter.name} onClick={() => handleCloseFilter(filter.type)}>
                {filter.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <Box>
        {employees.length !== 0 ? (
          employees
            .filter((employee) => !employee.active)
            .map((employee, index) => (
              <Accordion key={index} disableGutters elevation={0} square>
                <EmployeeSummary
                  index={index}
                  employee={employee}
                  partnes={partnes}
                  inactive={true}
                />
                <EmployeeDetail employee={employee} partnes={partnes} />
              </Accordion>
            ))
        ) : (
          <EmptyContent title="Não há usuários Inativos!" />
        )}
      </Box>
    </Card>
  );
}
