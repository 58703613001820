export const phoneFormatter = (data: string) => {
  const stringLength = data.length;
  const ddd = data.slice(0, 2);
  if (stringLength >= 11) {
    const start = data.slice(2, 7);
    const end = data.slice(7, 11);
    return `(${ddd}) ${start}-${end}`;
  } else if (stringLength < 11) {
    const start = data.slice(2, 6);
    const end = data.slice(6, 10);
    return `(${ddd}) ${start}-${end}`;
  }
  return data;
};
