import { Typography, AccordionSummary, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Avatar from 'src/components/Avatar';
import moment from 'moment';
import { formatCPF } from 'src/utils/cpf';

interface ClientSummaryProps {
  name: string;
  photoURL?: string;
  cpf: string;
  index: number;
  buttonDetails?: boolean;
  birthdate: string;
}

export function ClientSummary({ name, photoURL, cpf, index, birthdate }: ClientSummaryProps) {
  const theme = useTheme();
  return (
    <AccordionSummary>
      <div style={{ display: 'flex', width: '80%' }}>
        <div style={{ display: 'flex', width: '7%', alignItems: 'center' }}>
          <Avatar
            src={photoURL}
            alt={name}
            color={photoURL ? 'default' : index % 2 === 0 ? 'primary' : 'secondary'}
          >
            <Iconify icon={'fa-regular:user'} />
          </Avatar>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '70%', marginLeft: 10 }}>
          <h4>{name}</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="caption" fontSize="12px" color={theme.palette.grey[600]}>
              CPF: {formatCPF(cpf)} <br />
              DATA DE NASCIMENTO:{' '}
              {birthdate ? moment(new Date(birthdate)).format('DD/MM/YYYY') : 'Carregando...'}
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', width: '18%', justifyContent: 'flex-end' }}>
        <Iconify
          icon={'icon-park:down'}
          width={20}
          height={20}
          color={theme.palette.primary.main}
        />
      </div>
    </AccordionSummary>
  );
}
